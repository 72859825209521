import { GridProps } from "@/types/components/grid/grid";
import { forwardRef } from "react";

const Row = forwardRef<HTMLDivElement, GridProps>(({ children, className = '', ...props }, ref) => {
    // Your component implementation
    return (
        <div className={`row flex flex-wrap -mx-3 maxMd:-mx-4 ${className}`} ref={ref}>
            {children}
        </div>
    );
});

export default Row;